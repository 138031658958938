<template>
  <v-container fluid>
    <Filtros
      P_periodo
      P_moeda
      P_custo
      P_custom
      P_status
      :P_statusArray="filterStatus"
    />

    <v-card outlined class="mt-2">
      <v-card-title class="text-body-1 px-3 py-2 lightgray">
        <v-text-field
          class="mr-1"
          style="max-width: 300px"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          v-model="search"
          solo
          flat
          dense
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="contasReceber"
          :items-per-page="15"
          :sort-desc="true"
          @click:row="goToContoReceber"
          :class="{ 'data-tables__row-click': PClienteContasReceberEditar }"
          class="data-tables"
        >
          <template v-slot:item.fatura_id="{ item }">
            <div class="flex" v-if="item.fatura_id">
              {{ item.fatura_ponto }}
              {{ item.fatura_numero.toString().padStart(7, "0") }}
            </div>
            <span v-else>{{ $tc("global.semFatura") }}</span>
          </template>
          <template v-slot:item.data_vencimento="{ item }">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.data_pagamento="{ item }">
            {{ item.data_pagamento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.valor="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor | guarani }}
            </div>
            <div v-else>
              {{ item.valor | currency }}
            </div>
          </template>

          <template v-slot:item.juros="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.juros | guarani }}
            </div>
            <div v-else>
              {{ item.juros | currency }}
            </div>
          </template>
          <template v-slot:item.multa="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.multa | guarani }}
            </div>
            <div v-else>
              {{ item.multa | currency }}
            </div>
          </template>

          <template v-slot:item.desconto="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.desconto | guarani }}
            </div>
            <div v-else>
              {{ item.desconto | currency }}
            </div>
          </template>

          <template v-slot:item.valor_pago="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor_pago | guarani }}
            </div>
            <div v-else>
              {{ item.valor_pago | currency }}
            </div>
          </template>

          <template v-slot:item.saldo="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.saldo | guarani }}
            </div>
            <div v-else>
              {{ item.saldo | currency }}
            </div>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
    </v-card>
    <DialogContasReceberCliente
      v-if="dialogConta"
      :dialogConta.sync="dialogConta"
      :conta="conta"
      @fetch-contas="getContasReceber"
    />
  </v-container>
</template>

<script>
import { fetchContasReceber } from "@/api/contas-receber/contas_receber.js";
import { mapGetters } from "vuex";

export default {
  name: "ContasReceber",

  data() {
    return {
      loading: false,
      contasReceber: [],
      conta: {},
      search: "",
      dialogConta: false,
    };
  },

  components: {
    Filtros: () => import("@/components/filtros/Filtros.vue"),
    DialogContasReceberCliente: () =>
      import("./DialogContasReceberCliente.vue"),
  },

  props: {
    clienteId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PClienteContasReceberEditar() {
      return this.getAccess("ClienteContas", "editar");
    },
    filterStatus() {
      return [
        {
          text: "Contas Pagas",
          value: "pagas",
        },
        {
          text: "Em Aberto",
          value: "aberto",
        },
        {
          text: "Vencidas",
          value: "vencidas",
        },
      ];
    },

    cliente_id() {
      if (this.clienteId) {
        return this.clienteId;
      }
      return this.$route.params.id;
    },

    headers() {
      return [
        {
          text: "ID",
          value: "id",
          divider: true,
        },
        {
          text: this.$tc("global.ordem"),
          value: "ordem",
          divider: true,
        },
        {
          text: this.$tc("global.data-vencimento"),
          value: "data_vencimento",
          divider: true,
        },
        {
          text: this.$tc("global.data-pagamento"),
          value: "data_pagamento",
          divider: true,
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.juros"),
          value: "juros",
          align: "right",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.multa"),
          value: "multa",
          align: "right",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
          align: "right",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.pago"),
          value: "valor_pago",
          align: "right",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.saldo"),
          value: "saldo",
          align: "right",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.fatura"),
          width: "150px",
          align: "center",
          value: "fatura_id",
        },
      ];
    },

    url() {
      let queryString = `?cliente_id=${this.cliente_id}`;
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return queryString;
    },
  },

  watch: {
    url() {
      this.getContasReceber();
    },
  },

  methods: {
    goToContoReceber(item) {
      if (item && Number(item.saldo) > 0) {
        this.conta = { ...item };
        this.dialogConta = true;
      } else {
        this.$toast.error(this.$tc("global.contaPaga"));
      }
    },
    async getContasReceber() {
      this.loading = true;
      try {
        const response = await fetchContasReceber(this.url);
        this.contasReceber = response;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.getContasReceber();
  },
};
</script>

<style></style>
