var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Filtros',{attrs:{"P_periodo":"","P_moeda":"","P_custo":"","P_custom":"","P_status":"","P_statusArray":_vm.filterStatus}}),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-1 px-3 py-2 lightgray"},[_c('v-text-field',{staticClass:"mr-1",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.PClienteContasReceberEditar },attrs:{"headers":_vm.headers,"search":_vm.search.length >= 3 ? _vm.search : '',"items":_vm.contasReceber,"items-per-page":15,"sort-desc":true},on:{"click:row":_vm.goToContoReceber},scopedSlots:_vm._u([{key:"item.fatura_id",fn:function(ref){
var item = ref.item;
return [(item.fatura_id)?_c('div',{staticClass:"flex"},[_vm._v(" "+_vm._s(item.fatura_ponto)+" "+_vm._s(item.fatura_numero.toString().padStart(7, "0"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$tc("global.semFatura")))])]}},{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_pagamento,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.juros",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.juros))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.juros))+" ")])]}},{key:"item.multa",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.multa))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.multa))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.valor_pago",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor_pago))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_pago))+" ")])]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")])]}}],null,false,38674816)}):_c('Carregando')],1)],1),(_vm.dialogConta)?_c('DialogContasReceberCliente',{attrs:{"dialogConta":_vm.dialogConta,"conta":_vm.conta},on:{"update:dialogConta":function($event){_vm.dialogConta=$event},"update:dialog-conta":function($event){_vm.dialogConta=$event},"fetch-contas":_vm.getContasReceber}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }